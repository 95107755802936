import React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '../styles/global';
import theme from '../styles/theme';
import Navbar from './navbar';
import BottomBar from './footer';

const Layout = ({ children, page }) => {
	return (
		<ThemeProvider theme={theme}>
			<>
				<GlobalStyle />
				<Navbar page={page} />
				{children}

				<BottomBar />
			</>
		</ThemeProvider>
	);
};

export default Layout;
