import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledFooter = styled.div`
	font-family: ${(p) => p.theme.fonts.title};

	.container {
		height: 10.5rem;
	}
	.credits {
		height: 3rem;
	}
	.columns {
		display: flex;
		justify-content: space-between;
		margin: 0;
		.column {
			width: 42.5%;
			display: flex;
			flex: none;
			align-items: center;
			text-align: center;
			justify-content: space-between;
			flex: 1 1 auto;

			&.bottom-logo {
				width: 15%;
				text-align: center;
				flex: 2 1 auto;

				.image-container {
					width: 100%;
					max-width: 150px;
					margin: 1rem auto;
				}
			}
			.item-holder {
				display: flex;
				width: 100%;
				justify-content: space-around;
				.bottom-item {
					color: ${(p) => p.theme.color.dark};
					font-weight: 700;
					font-size: 1.0625rem;
					text-transform: uppercase;
					position: relative;
					line-height: 1;

					&::after {
						content: '';
						background-color: ${(p) => p.theme.color.dark};
						height: 2px;
						width: 100%;
						left: 0;
						bottom: 0;
						position: absolute;
						opacity: 0;
						transition: 0.5s;
					}
					&:hover::after {
						opacity: 1;
						@media all and (max-width: 668px) {
							opacity: 0;
						}
					}
				}
			}
		}
	}
	.credits {
		background-color: ${(p) => p.theme.color.darkBlue};
		padding: 0.5rem 1rem;
		p {
			color: #fff;
			margin-bottom: 0rem;
		}
	}
	@media all and (max-width: 768px) {
		.container {
			height: 8.5rem;
		}
		.credits {
			height: 3rem;
		}
	}
	// Some styles here.
	@media all and (max-width: 668px) {
		.container {
			height: 26rem;
			height: auto;
		}
		.credits {
			height: 3rem;
		}
		.columns {
			flex-direction: column;
			padding: 0 0 2rem;
			.column {
				width: 100%;
				padding: 0;
				&.bottom-logo {
					width: 100%;
					order: -1;
					.image-container {
						max-width: 200px;
						width: 90%;
						margin: 2rem auto 0.5rem;
					}
				}
				.item-holder {
					flex-direction: column;
					text-align: center;
					.bottom-item {
						padding: 0.5rem 0;
					}
				}
			}
		}
	}
	@media all and (max-width: 500px) {
		.credits {
			padding: 0.5rem 2rem;
			height: 4rem;
		}
	}
`;

export default () => {
	return (
		<StyledFooter>
			<div className="container">
				<div className="columns is-multiline">
					<div className="column ">
						<div className="item-holder">
							<Link to="/privacy/" className="bottom-item">
								Privacy
							</Link>
							<Link to="/disclosures/" className="bottom-item">
								Disclosures
							</Link>
							<Link to="/claims/" className="bottom-item">
								Claims
							</Link>
						</div>
					</div>
					<div className="column bottom-logo ">
						<div className="image-container">
							<Link to="/" className="bottom-item">
								<img
									src="/img/EIBL-FooterLogo-01.svg"
									className="bottom-image"
									alt="Edwards Collwill Logo"
								/>
							</Link>
						</div>
					</div>
					<div className="column ">
						<div className="item-holder">
							<a
								href="/img/CISRO_Principles_of_Conduct_for_Insurance_Intermediaries.pdf"
								className="bottom-item"
								target="_blank"
								rel="noopener noreferrer"
							>
								CISRO Principles
							</a>
							<a
								href="/img/RIBO_Conduct_Sheet_040622-fact_sheet.pdf"
								className="bottom-item"
								target="_blank"
								rel="noopener noreferrer"
							>
								RIBO Fact Sheet
							</a>
							<Link to="/contact" className="bottom-item">
								Contact
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="credits">
				<div className="container has-text-centered">
					<p>
						©{new Date().getFullYear()}, Edwards Insurance Brokers, All Rights
						Reserved
					</p>
				</div>
			</div>
		</StyledFooter>
	);
};
