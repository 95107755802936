import { createGlobalStyle } from 'styled-components';

import bulma from './bulmaSetup.scss';

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css?family=Merriweather:300,700&display=swap');
	@import url('https://use.typekit.net/lfj4glx.css');

	${bulma}


	body {
		font-size: 16px;
		color: ${(p) => p.theme.color.dark};
		background-color: #fff;
		font-family: ${(props) => props.theme.fonts.body} !important;
		overflow: hidden;
		h1 {
			font-size: 3rem;
			line-height: 3.5rem;
			color: #fff;
			font-family: ${(p) => p.theme.fonts.title};
		}
		h2 {
			font-size: 1.5rem;
			line-height: 2rem;
		font-family: ${(props) => props.theme.fonts.title};
			color: #fff;
		}
		p {
			line-height: 1.7rem;
		}
		hr {
			background-color: ${(p) => p.theme.color.orange};
			height: 3px;
			width: 50px;
		}
		button {
			cursor: pointer;
			appearance: none;
			padding: 0;
			margin: 0;
			font-family: inherit;
			font-size: .9rem;
    		text-decoration: none;
			border: none;
			color: #fff;
			background-color: transparent;
		}
		a, button {
			color: ${(p) => p.theme.color.grey};
			transition: .5s;

			&.ec-button {
				font-family: ${(p) => p.theme.fonts.title};
				font-weight: 400;		
				background-color: ${(p) => p.theme.color.orange};
				color: #fff;
				padding: 0.5rem 1rem;
				text-decoration: none;
				text-transform: uppercase;
				&:hover {
					background-color: #c8881f;
				}
			}
		}
		input, textarea {
			border: none;
			border-radius: unset;
			font-size: .9rem;
			font-family: ${(props) => props.theme.fonts.title};
		}

		a.white-link,
		.white-link a {
			color: #fff;
			position: relative;
			&::after {
				content: '';
				background-color: #fff;
				height: 1px;
				width: 100%;
				left: 0;
				bottom: 0;
				position: absolute;
				opacity: 0;
				transition: 0.5s;
			}
			&:hover::after {
				opacity: 1;
			}
		}
		
		@media all and (min-width: 1600px) {
			h1 {
				font-size: 4rem;
				line-height: 4.5rem;
			}
			h2 {
				font-size: 2rem;
				line-height: 2.5rem;
			}
			p {
				font-size: 1.25rem;
				line-height: 1.8rem;
			}
			a, button, a.ec-button, button.ec-button {
				font-size: 1.3rem;
				line-height: 1.7rem;
			}
			input, textarea {
				font-size: 1.25rem;
			}
		}
	}
`;

export default GlobalStyle;
