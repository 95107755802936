import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const NavStyle = styled.div`
	.navbar.mobileOpen {
		position: fixed;
	}
	.bolded {
		font-weight: 700;
	}
	.navbar {
		position: fixed;
		padding-left: ${(p) => p.theme.gutter};
		background-color: ${(p) => p.theme.color.translucent};
		width: 100%;
		height: 6rem;

		.top-bar {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			margin-top: -2rem;
			height: 2rem;
			background-color: ${(p) => p.theme.color.blue};
			color: #fff;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.text-container {
				margin-left: auto;
				margin-right: 0;
				@media screen and (-ms-high-contrast: active),
					(-ms-high-contrast: none) {
					position: absolute;
					top: 0;
					right: 0;
				}

				.hide-mobile {
					margin-right: 5px !important;
				}

				.phone-text {
					margin-right: 1.5rem;
					position: relative;
					font-family: ${(p) => p.theme.fonts.light};
				}
				.head-office {
					margin-right: 0 !important;
				}
				a {
					font-family: ${(p) => p.theme.fonts.light};
					padding-left: 0.5rem;
					padding-right: 0;
					color: #fff;
					&::after {
						content: '';
						background-color: #fff;
						transition: 0.5s;
						width: 45%;
						height: 1px;
						position: absolute;
						bottom: 3px;
						right: 0;
						opacity: 0;
					}
					&:hover::after {
						opacity: 1;
					}
				}

				.claims-link::after {
					display: none;
				}
			}
		}
		.navbar-brand {
			height: 100%;
			position: relative;
			a {
				padding: 1rem 0;
				position: relative;
				width: 110px;
				img {
					max-height: none;
				}
			}
		}
		.navbar-menu {
			a {
				color: #fff;
				font-family: ${(p) => p.theme.fonts.title};
				text-transform: uppercase;
				padding: 0 1.5rem;
				border: 1px solid transparent;
				border-left: 1px solid #c8c8c8;
				background: none;
				transition: 0.5s;
				&:hover {
					background-color: ${(p) => p.theme.color.blue};
				}
				&.quote-link {
					background-color: ${(p) => p.theme.color.orange};
					flex-direction: column;
					justify-content: center;
					text-transform: none;
					line-height: 1rem;
					&:hover {
						background-color: ${(p) => p.theme.color.blue};
					}
				}
			}
		}

		@media all and (min-width: 1200px) {
			padding-left: ${(p) => p.theme.gutterWide};
		}
		@media all and (min-width: 1600px) {
			padding-left: ${(p) => p.theme.gutterXWide};
			height: 6.8rem;
			.top-bar {
				height: 2.5rem;
				margin-top: -2.5rem;
			}
			.navbar-brand a {
				width: 110px;
			}
			a,
			span {
				font-size: 1.3rem;
				line-height: 1.5rem;
			}
			.navbar-menu a {
				padding: 0 2.5rem;
			}
		}
		@media all and (max-width: 1050px) {
			.navbar-brand a {
				width: 110px;
				padding: 0.7rem 0;
			}
			.navbar-menu a {
				padding: 0 1rem;
			}
		}
		@media all and (max-width: 950px) {
			position: absolute;
			top: 2rem;
			.navbar-burger {
				color: #fff;
				position: absolute;
				top: 50%;
				right: 30px;
				z-index: 999;
				transition: 0.4s;
				background: none;
				height: 71px;
				width: 71px;
				transform: translateY(-50%);
				span {
					width: 30px;
					height: 2px;
					left: calc(50% - 16px);
					&:nth-child(1) {
						top: calc(50% - 10px);
					}
					&:nth-child(2) {
						top: calc(50% - 1px);
					}
					&:nth-child(3) {
						top: calc(50% + 8px);
					}
				}

				&.is-active {
					transform: rotate(90deg);
					span {
						&:nth-child(1) {
							top: calc(50% - 6px);
						}
						&:nth-child(2) {
							opacity: 0;
						}
						&:nth-child(3) {
							top: calc(50% + 4px);
						}
					}
				}
			}

			.navbar-menu {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 2rem 0 0;
				height: 100%;
				width: 100%;
				position: fixed;
				top: 0;
				left: -100%;
				opacity: 0;
				background-color: ${(p) => p.theme.color.translucent};
				transition: opacity ease-in 0.3s;
				&.is-active {
					transition: opacity ease-in 0.3s;
					opacity: 1;
					left: 0;
				}
				.navbar-item {
					font-size: 2rem;
					padding: 1rem !important;
					border: none;
					text-align: center;
					background: none !important;
					&.quote-link {
						display: none;
					}
				}
			}
		}
		@media all and (max-width: 550px) {
			.top-bar .text-container {
				margin: 0 auto;
				width: 80%;
				text-align: center;
				span:first-child {
					margin-right: 1rem;
				}
				span:last-child {
					margin-left: 1rem;
					margin-right: 0;
				}
			}
		}
		@media all and (max-width: 500px) {
			padding: 1rem 2rem;
			top: 2.5rem;
			.navbar-burger {
				top: 6px;
			}
			.navbar-menu {
				padding: 1.7rem 0 0;
				.navbar-item {
					padding-left: 2rem;
				}
			}
			.top-bar {
				height: 2.5rem;
				margin-top: -2.5rem;
				span {
					font-size: 1.2rem;
				}
				.phone {
					position: relative;
					&::before {
						content: url(/img/phone-white.svg);
						height: 15px;
						width: 15px;
						margin: 0;
						padding: 0;
						position: absolute;
						left: -15px;
						top: 0;
					}
				}
				.head-office {
					display: none;
				}
			}
		}
		@media all and (max-width: 400px) {
			.top-bar .text-container {
				span {
					text-align: center;
					a::after {
						display: none;
					}
				}
				.hide-mobile {
					display: none;
				}
			}
		}
	}
	.quotebar {
		width: 100%;
		position: fixed;
		left: 0;
		top: 8rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: ${(p) => p.theme.color.orange};
		text-align: center;
		height: 3rem;
		a {
			font-family: ${(p) => p.theme.fonts.title};
			font-size: 1.2rem;
			color: #fff;
		}
		@media all and (min-width: 951px) {
			display: none;
		}
		@media all and (max-width: 950px) {
			position: absolute;
		}
		@media all and (max-width: 500px) {
			top: 7.8rem;
		}
	}
`;

const Navbar = ({ page }) => {
	const [mobileMenu, setMobileMenu] = useState(false);

	return (
		<NavStyle>
			<>
				<nav className={mobileMenu ? 'navbar mobileOpen' : 'navbar'}>
					<div className="top-bar">
						<div className="text-container">
							<span className="phone-text">
								<Link className="claims-link" to="/claims/">
									<span className="hide-mobile">24/7</span>
									CLAIMS:
								</Link>
								<a href="tel:+18775224258">877.522.4258</a>
							</span>
							<span className="phone-text">
								<span className="head-office">HEAD OFFICE:</span>
								<a className="phone" href="tel:+17057197956">
									705.719.7956
								</a>
							</span>
						</div>
					</div>
					<div className="navbar-brand">
						<Link className="navbar-item" to="/">
							<img src="/img/EC_Logo.svg" alt="Edwards Collwill Logo" />
						</Link>
						<div
							role="button"
							className={
								mobileMenu ? 'navbar-burger is-active' : 'navbar-burger'
							}
							onClick={() => {
								if (!mobileMenu) setMobileMenu(true);
								else setMobileMenu(false);
							}}
						>
							<span />
							<span />
							<span />
						</div>
					</div>
					<div className={mobileMenu ? 'navbar-menu is-active' : 'navbar-menu'}>
						<div className="navbar-end">
							<Link
								className="navbar-item"
								to="/about/"
								onClick={() => {
									if (!mobileMenu) setMobileMenu(true);
									else setMobileMenu(false);
								}}
							>
								about
							</Link>
							<Link
								className="navbar-item"
								to="/commercial/"
								onClick={() => {
									if (!mobileMenu) setMobileMenu(true);
									else setMobileMenu(false);
								}}
							>
								commercial
							</Link>
							<Link
								className="navbar-item"
								to="/personal/"
								onClick={() => {
									if (!mobileMenu) setMobileMenu(true);
									else setMobileMenu(false);
								}}
							>
								personal
							</Link>
							<Link
								className="navbar-item"
								to="/resources/"
								onClick={() => {
									if (!mobileMenu) setMobileMenu(true);
									else setMobileMenu(false);
								}}
							>
								resources
							</Link>
							<Link
								className="navbar-item"
								to="/contact/"
								onClick={() => {
									if (!mobileMenu) setMobileMenu(true);
									else setMobileMenu(false);
								}}
							>
								contact
							</Link>
							<Link
								className="navbar-item quote-link"
								to={`/contact/?page=${page}`}
							>
								Get a <span className="bolded">QUOTE</span>
							</Link>
						</div>
					</div>
				</nav>
				<div className="quotebar">
					<Link to={`/contact/?page=${page}`}>
						GET A <span className="bolded">QUOTE</span>
					</Link>
				</div>
			</>
		</NavStyle>
	);
};

export default Navbar;
