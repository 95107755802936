export default {
	color: {
		blue: '#587f97',
		darkBlue: '#2e4254',
		dark: '#4e4c50',
		translucent: 'rgba(78,76,80,.95)',
		grey: '#a8a9ac',
		orange: '#e8a942',
		yellow: '#f8c95c',
	},
	fonts: {
		light: 'benton-sans-compressed, sans-serif',
		body: 'Merriweather, serif',
		title: 'proxima-nova, sans-serif',
	},
	gutterMobile: '1.5rem',
	gutter: '4rem',
	gutterWide: '8rem',
	gutterXWide: '10rem',
};
